<template>
  <div>
    <div></div>
    <div>
      <div class="font-24 mt-10 ml-4">
        Best Seller Sequencing
        <span v-if="details.query" class="tableTotal font-15 poppins"
          >(Total : {{ details.query.length }})</span
        >
      </div>
      <div class="justify-center" align="center">
        <!-- Top Bar Chart -->
        <v-card class="mt-2 mx-4">
          <div class="panel-content">
            <div class="row">
              <div class="col-md-12">
                <div class="widget">
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <v-form
                        name="details"
                        class="formPart text-left"
                        @submit.prevent="submitForm(id)"
                      >
                        <div>
                          <div class="form-group col">
                            <label for="link"
                              >Palettier SKU Codes:
                              <v-btn @click="addSku">Add</v-btn>
                            </label>

                            <div class="poppins-medium font-16 pl-4 d-block">
                              <v-row no-gutters>
                                <v-col
                                  cols="4"
                                  v-for="(item, index) in details.query"
                                  :key="index"
                                  class="px-6"
                                >
                                  <div class="d-flex sequencing-field">
                                    <div>
                                      <v-text-field
                                        type="number"
                                        v-model="item.sequenceNumber"
                                        max-width="60px"
                                        dense
                                        placeholder="S.No"
                                        class="mt-6 mr-2 px-4 seq-field"
                                      ></v-text-field>
                                      <!-- <div
                                        class="validation mt-4 mr-1"
                                        v-if="
                                          !$v.details.query.$each[index].sequenceNumber.required &&
                                          $v.details.query.$each[index].sequenceNumber.$error
                                        "
                                      >
                                        Seq is required
                                      </div> -->
                                    </div>

                                    <div>
                                      <div class="d-flex">
                                        <v-text-field
                                          v-if="item"
                                          v-model="item.sku"
                                          width="200px"
                                          outlined
                                          dense
                                          class="mt-6 mr-4 px-2"
                                          >{{ item.sku }}</v-text-field
                                        >
                                        <img
                                          @click="deleteSKU(index)"
                                          src="@/assets/Images/remove.svg"
                                          alt="logo"
                                          class="mt-2"
                                        />
                                      </div>

                                      <div
                                        class="validation"
                                        v-if="
                                          !$v.details.query.$each[index].sku.required &&
                                          $v.details.query.$each[index].sku.$error
                                        "
                                      >
                                        Sku is required
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="validation mt-1"
                                    v-if="item.sku && item.sku.length < 0"
                                  >
                                    sequence is required
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </div>
                        <!-- button -->
                        <div class="btnPart text-center my-4">
                          <div class="mt-2 mb-2" v-if="loader" align="center">
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </div>
                          <button :disabled="loader" type="submit" class="btn btn-success">
                            Save
                          </button>
                          <p class="typo__p" v-if="submitStatus === 'OK'">
                            Thanks for your submission!
                          </p>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'">
                            Please fill the form correctly.
                          </p>
                          <v-btn color="error" class="mx-3" v-on:click="cancelSku()">
                            Cancel
                          </v-btn>
                        </div>
                      </v-form>
                      <div
                        class="
                          table-body-contents
                          text-center
                          m-3
                          font-size-md font-weight-bold
                          text-muted
                        "
                        v-if="details.query && details.query.length === 0"
                      >
                        No Data Found
                      </div>
                    </div>
                  </div>
                  <ConfirmDlg ref="confirm" />
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import productSequence from '../../services/productSequence';
import ConfirmDlg from '../../components/ConfirmDlg.vue';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      details: {
        query: [],
      },

      submitStatus: false,
      id: '',
      mobileUrl: null,
      desktopUrl: null,
      loader: false,
    };
  },
  validations: {
    details: {
      query: {
        $each: {
          sku: {
            required,
          },
          sequenceNumber: {
            required,
          },
        },
      },
    },
  },
  created() {
    this.getAllSequence();
  },
  methods: {
    setTouch(fields) {
      // else if (fields === 'link') {
      //   this.$v.details.link.$touch();
      // }
    },
    async getAllSequence() {
      const result = await productSequence.getAllSequence();
      let apiQuery = [];
      if (
        result.status == 200 &&
        result.data &&
        result.data.query &&
        result.data.query.length > 0
      ) {
        apiQuery = result.data.query;
        this.details.query = apiQuery;
        // this.seqNum = result.data.sequenceNumber;
        console.log('sequenceget', result.data, this.id, this.details.query);
      }
      if (result.data._id) {
        this.id = result.data._id;
      }
    },
    // this is use for save button
    async submitForm(id) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });

        this.submitStatus = 'false';
      } else {
        console.log(id, 'id');
        this.loader = true;
        this.submitStatus = 'true';
        let query = [];
        await this.details.query.map((sku) => {
          let data = {};
          console.log(sku, 'sku-here');
          if (sku.sku && sku.sku != '') {
            data.sku = sku.sku.trim();
            data.sequenceNumber = sku.sequenceNumber;
            query.push(data);
          }
        });
        this.details.query = query;
        console.log(this.id, 'id here');
        if (this.id && this.id != '') {
          console.log('freshid:;', this.id);
          const result = await productSequence.updateSequence(this.id, this.details);
          console.log('update:::', result);
          if (result.status === 200) {
            this.loader = false;
            if (
              result &&
              result.data &&
              result.data.data &&
              result.data.data === 'Sequence already create'
            ) {
              this.$store.commit('setSnackbar', {
                content: result.data.data,
                icon: 'mdi-alert-circle',
                color: 'error ',
                isVisible: true,
              });
            } else {
              // this.submitStatus = 'OK';
              this.$store.commit('setSnackbar', {
                content: 'Submitted Successfully',
                icon: 'mdi-check-circle',
                color: 'success ',
                isVisible: true,
              });
              // this.$router.push('/bestSeller');
            }
          } else {
            this.$store.commit('setSnackbar', {
              content: 'Something went wrong !',
              icon: 'mdi-alert-circle',
              color: 'error ',
              isVisible: true,
            });
          }
        } else {
          const result = await productSequence.createAdmin(this.details);
          if (result.status === 200) {
            this.loader = false;
            if (
              result &&
              result.data &&
              result.data.data &&
              result.data.data === 'Sequence already create'
            ) {
              this.$store.commit('setSnackbar', {
                content: result.data.data,
                icon: 'mdi-alert-circle',
                color: 'error ',
                isVisible: true,
              });
            } else {
              // this.submitStatus = 'OK';
              this.$store.commit('setSnackbar', {
                content: 'Submitted Successfully',
                icon: 'mdi-check-circle',
                color: 'success ',
                isVisible: true,
              });
              // this.$router.push('/bestSeller');
            }
          } else {
            this.$store.commit('setSnackbar', {
              content: 'Something went wrong !',
              icon: 'mdi-alert-circle',
              color: 'error ',
              isVisible: true,
            });
          }
          console.log(result);
        }
      }
    },

    async cancelSku() {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.details.query = [];
        this.id = '';
        this.$nextTick(() => {
          this.$v.$reset();
          // this.$router.push('/bestSeller');
        });
      }
    },
    async addSku() {
      const data = {};
      data.sku = '';
      this.details.query.push(data);
      this.SequenceNumbers();
      console.log('***query', this.details.query);
    },
    SequenceNumbers() {
      this.details.query.forEach((item, index) => {
        item.sequenceNumber = index + 1;
      });
    },
    async deleteSKU(index) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        let data = {};
        data.sku = await this.details.query[index];
        this.details.query.splice(index, 1);
        const result = await productSequence.deletedSkuUpdateAdmin(data.sku);
        this.SequenceNumbers();
        this.submitForm();
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.validation {
  color: red;
}
.preview-image {
  width: 40%;
  height: 250px;
}
.sequencing-field {
  .seq-field {
    max-width: 70px !important;
  }
}
</style>
