import axios from 'axios';


const adminUrl = process.env.VUE_APP_API_URL;
// const uploadUrl = process.env.VUE_APP_FILE_UPLOAD_URL;

export default {
  async getAllSequence(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrl}/sequence/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateSequence(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/sequence/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createAdmin(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/sequence/createAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  deletedSkuUpdateAdmin(data) {
    return axios.post(`${adminUrl}/sequence/updateDeletedSkuAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
};
